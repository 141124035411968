<ion-header class="ion-no-border custom-header">
    <ion-toolbar>
      <ion-buttons slot="start">
        @if (!isMainPage) {
            <ion-button fill="clear" (click)="back()">
              <img src="../../../../assets/ic-back-clay.svg" alt="navigate back button" />
            </ion-button>
        }
        <p class="ss-logo">Sunny Street</p>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button (click)="goToNotificationsPage()" class="btn-ic-notification" fill="clear">
          <ion-icon name="notifications"></ion-icon>
          @if (notificationCount > 0){
            <ion-badge color="danger" class="icon-badge">{{notificationCount}}</ion-badge>
          }
        </ion-button>
        <ion-button (click)="goToMenuPage()" id="btn-ic-menu" fill="clear">
          <div class="profile-avatar">
            <img alt="avatar" [src]="_imageUrl" />
          </div>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" force-overscroll="true">
  <ion-refresher slot="fixed" [pullFactor]="0.5" [pullMin]="100" [pullMax]="200">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-tabs (ionTabsWillChange)="handleTabChange($event)">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="charity-info">
        <ion-icon
          [src]="
            currentTab === 'charity-info' ? '/assets/ic-trend.svg' : '/assets/ic-trend-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="newsfeed">
        <ion-icon
          [src]="
            currentTab === 'newsfeed'
              ? '/assets/ic-feed.svg'
              : '/assets/ic-feed-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="commerce">
        <ion-icon
          [src]="
            currentTab === 'commerce' ? '/assets/ic-bag.svg' : '/assets/ic-bag-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

    </ion-tab-bar>
  </ion-tabs>
</ion-content>
