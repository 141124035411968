import { Injectable } from '@angular/core';
import { StateType } from 'src/app/core/models/StateType';

const tabs: string[] = [
  '/charity-role-tabs/charity-info',
  '/charity-role-tabs/newsfeed',
  '/charity-role-tabs/commerce',
  '/user-role-tabs/home',
  '/user-role-tabs/discover',
  '/user-role-tabs/commerce',
];

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private userTabHistory: { [key: string]: { url: string; state: StateType }[] } = {
    'home': 
      [{ url: '/user-role-tabs/home', state: {} }],
    'discover': 
      [{ url: '/user-role-tabs/discover', state: {} }],
    'commerce': 
      [{ url: '/user-role-tabs/commerce', state: {} }],
  };
  private charityTabHistory: { [key: string]: { url: string; state: StateType }[] } = {
    'charity-info': 
      [{ url: '/charity-role-tabs/charity-info', state: {} }],
    'newsfeed': 
      [{ url: '/charity-role-tabs/newsfeed', state: {} }],
    'commerce': 
      [{ url: '/charity-role-tabs/commerce', state: {} }],
  };
  private lastTab: { user: string; charity: string } = { user: 'home', charity: 'charity-info' };

  constructor() {}

  trackNavigation(portal: 'user' | 'charity', tab: string, url: string, state: StateType): void {
    if (!url.startsWith('/user-role-tabs') && portal === 'user') return;
    if (!url.startsWith('/charity-role-tabs') && portal === 'charity') return;
    if (tabs.includes(url)) return;

    const history: { [key: string]: { url: string; state: StateType }[] } = portal === 'user' ? this.userTabHistory : this.charityTabHistory;
    const tabHistory: { url: string; state: StateType }[] = history[tab] || [];
    if (tabHistory.length === 0 || tabHistory[tabHistory.length - 1]?.url !== url) {
      tabHistory.push({ url, state });
      history[tab] = tabHistory;

    }
    this.lastTab[portal] = tab;
  }

  getLastTab(portal: 'user' | 'charity'): string {
    if(portal === 'user') {
      return this.lastTab.user;
    } else {
      return this.lastTab.charity;
    }
  }

  getLastPage(portal: 'user' | 'charity'): { url: string; state: StateType } | null {
    const lastTab: string = this.lastTab[portal];
    const history: { [key: string]: { url: string; state: StateType }[] }  = portal === 'user' ? this.userTabHistory : this.charityTabHistory;
    return history[lastTab]?.[history[lastTab].length - 1] || null;
  }

  getLastTabPage(portal: 'user' | 'charity', tab: string): { url: string; state: StateType } | null {
    const history: { [key: string]: { url: string; state: StateType }[] } = portal === 'user' ? this.userTabHistory : this.charityTabHistory;
    return history[tab][history[tab].length - 1] || null;
  }

  navigateBack(portal: 'user' | 'charity', tab: string): { url: string; state: StateType } | null {
    const history: { [key: string]: { url: string; state: StateType }[] } = portal === 'user' ? this.userTabHistory : this.charityTabHistory;
    if (history[tab].length > 1) history[tab].pop();
    return history[tab][history[tab].length - 1] || null;
  }

}
