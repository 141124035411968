import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/services/auth/auth.guard';
import { UserRoleTabsComponent } from './role-user/user-role-tabs/user-role-tabs.component';
import { CharityRoleTabsComponent } from './role-charity/charity-role-tabs/charity-role-tabs.component';

const routes: Routes = [
  // Auth Pages
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'start', loadComponent: () => import('./auth/start/start.page').then(c => c.StartPage) },
  { path: 'welcome', loadComponent: () => import('./auth/welcome/welcome.page').then(c => c.WelcomePage) },
  { path: 'login', loadComponent: () => import('./auth/login/login.page').then(c => c.LoginPage) },
  { path: 'signup', loadComponent: () => import('./auth/signup/signup.page').then(c => c.SignupPage) },
  { path: 'reset-password', loadComponent: () => import('./auth/reset-password/reset-password.page').then(c => c.ResetPasswordPage) },
  { path: 'forgot-password', loadComponent: () => import('./auth/forgot-password/forgot-password.page').then(c => c.ForgotPasswordPage) },
  // User Pages
  {
    path: 'user-role-tabs',
    component: UserRoleTabsComponent,
    canActivate: [authGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      { path: 'home', loadComponent: () => import('./role-user/tab-home/home/home.page').then(c => c.HomePage) },
      { path: 'my-charities', loadComponent: () => import('./role-user/tab-home/my-charities/my-charities.page').then(c => c.MyCharitiesPage) },
      { path: 'history', loadComponent: () => import('./role-user/tab-home/giving-history/giving-history.page').then(c => c.GivingHistoryPage)},
      { path: 'discover', loadComponent: () => import('./role-user/tab-discover/discover/discover.page').then(c => c.DiscoverPage) },
      { path: 'charity/:id', loadComponent: () => import('./role-user/tab-discover/charity/charity.page').then(c => c.CharityPage) },
      { path: 'impact-points/:id', loadComponent: () => import('./role-user/tab-discover/charity-impact-points/charity-impact-points.page').then(c => c.CharityImpactPointsPage) },
      { path: 'impact-point/:id', loadComponent: () => import('./role-user/tab-discover/impact-point/impact-point.page').then(c => c.ImpactPointPage) },
      { path: 'social', loadComponent: () => import('./role-user/tab-discover/social/social.page').then(c => c.SocialPage) },
      { path: 'commerce', loadComponent: () => import('./role-user/tab-commerce/commerce/commerce.page').then(c => c.CommercePage) },
      { path: 'item-detail/:id', loadComponent: () => import('./role-user/tab-commerce/item-detail/item-detail.component').then(c => c.ItemDetailComponent) },
      { path: 'auctions/:id', loadComponent: () => import('./role-user/tab-commerce/charity-auction/charity-auction.component').then(c => c.CharityAuctionComponent) },
      { path: 'notifications', canActivate: [authGuard], loadComponent: () => import('./role-user/notifications/notifications.page').then(c => c.NotificationsPage) }
    ]
  },
  {
    path: 'menu',
    children: [
      { path: '', canActivate: [authGuard], loadComponent: () => import('./role-user/menu/menu.page').then(c => c.MenuPage) },
      { path: 'manage-charity/:id', canActivate: [authGuard], loadComponent: () => import('./role-user/menu//charity-management/manage-charity/manage-charity.component').then(c => c.ManageCharityComponent) },
      { path: 'search-charity/:id', canActivate: [authGuard], loadComponent: () => import('./role-user/menu//charity-management/search-charity/search-charity.component').then(c => c.SearchCharityComponent) },
    ]
  },
  { path: 'donation-confirmation', canActivate: [authGuard], loadComponent: () => import('./role-user/tab-discover/donation-confirmation/donation-confirmation.component').then(c => c.DonationConfirmationComponent) },
  // Charity Pages
  {
    path: 'charity-role-tabs',
    component: CharityRoleTabsComponent,
    canActivate: [authGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'charity-info'},
      { path: 'charity-info', loadComponent: () => import('./role-charity/tab-charity/charity-info/charity-info.page').then(c => c.CharityInfoPage) },
      { path: 'newsfeed', loadComponent: () => import('./role-charity/tab-newsfeed/charity-newsfeed/charity-newsfeed.page').then(c => c.CharityNewsfeedPage) },
      { path: 'commerce', loadComponent: () => import('./role-charity/tab-commerce/charity-commerce/charity-commerce.page').then(c => c.CharityCommercePage) },
      { path: 'charity-item-detail/:id', loadComponent: () => import('./role-charity/tab-commerce/charity-item-detail/charity-item-detail.component').then(c => c.CharityItemDetailComponent) },
      { path: 'impact-point-detail/:id', loadComponent: () => import('./role-charity/tab-newsfeed/impact-point-detail/impact-point-detail.component').then(c => c.ImpactPointDetailComponent) },
      { path: 'charity-auction-detail/:id', loadComponent: () => import('./role-charity/tab-commerce/charity-auction-detail/charity-auction-detail.component').then(c => c.CharityAuctionDetailComponent) },
      { path: 'charity-notifications', loadComponent: () => import('./role-charity/charity-notifications/charity-notifications.page').then(c => c.CharityNotificationsPage) },
      { path: 'orders', loadComponent: () => import('./role-charity/tab-commerce/orders/orders.component').then(c => c.OrdersComponent) },
      { path: 'order-details/:id', loadComponent: () => import('./role-charity/tab-commerce/order-details/order-details.component').then(c => c.OrderDetailsComponent) }
    ],
  },
  { path: 'charity-role-menu', loadComponent: () => import('./role-charity/charity-role-menu/charity-role-menu.page').then(c => c.CharityRoleMenuPage)},
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
