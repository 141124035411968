<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (!isMainPage) {
      <ion-button class="btn-back" (click)="back()" (keydown)="back()" tabindex="{0}">
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
      }
      <ion-text class="ss-logo">Sunny Street</ion-text>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button
        (click)="onInvite()"
        (keydown)="onInvite()"
        tabindex="{0}"
        class="btn-ic-invite"
        fill="clear"
      >
        <ion-icon slot="icon-only" src="/assets/ic-paper-airplane.svg"></ion-icon>
      </ion-button>
      <ion-button (click)="goToNotificationsPage()" class="btn-ic-notification" fill="clear">
        <ion-icon slot="icon-only" name="notifications"></ion-icon>
        @if (notificationCount > 0){
        <ion-badge color="danger" class="icon-badge">{{ notificationCount }}</ion-badge>
        }
      </ion-button>
      <ion-button (click)="goToMenuPage()" class="btn-ic-menu" fill="clear">
        <div class="profile-avatar">
          <img alt="avatar" [src]="avatarUrl" />
        </div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <ion-refresher
    slot="fixed"
    [pullFactor]="0.5"
    [pullMin]="100"
    [pullMax]="200"
    (ionRefresh)="handleRefresh($event)"
  >
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-tabs (ionTabsWillChange)="handleTabChange($event)">
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="home">
        <ion-icon
          [src]="currentTab === 'home' ? '/assets/ic-trend.svg' : '/assets/ic-trend-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="discover">
        <ion-icon
          [src]="
            currentTab === 'discover'
              ? '/assets/ic-search-filled.svg'
              : '/assets/ic-search-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="commerce">
        <ion-icon
          [src]="currentTab === 'commerce' ? '/assets/ic-bag.svg' : '/assets/ic-bag-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <!-- <ion-tab-button tab="social">
        <ion-icon
          [src]="currentTab === 'social' ? '/assets/ic-feed.svg' : '/assets/ic-feed-outline.svg'"
        ></ion-icon>
      </ion-tab-button>

      <ion-tab-button tab="profile">
        <ion-icon
          [src]="
            currentTab === 'profile' ? '/assets/ic-person.svg' : '/assets/ic-person-outline.svg'
          "
        ></ion-icon>
      </ion-tab-button> -->
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
