import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { searchOutline, notifications, menu } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonBadge
} from '@ionic/angular/standalone';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CharityService } from 'src/app/core/services/charity/charity.service';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { TabService } from 'src/app/core/services/navigation/tab.service';
import { StateType } from 'src/app/core/models/StateType';

const CHARITY_TABS: string[] = [
  '/charity-role-tabs/charity-info',
  '/charity-role-tabs/newsfeed',
  '/charity-role-tabs/commerce',
];

const charityTabs: string[] = [
  'charity-info',
  'newsfeed',
  'commerce',
];

@Component({
  selector: 'app-charity-role-tabs',
  templateUrl: './charity-role-tabs.component.html',
  styleUrls: ['./charity-role-tabs.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonBadge
  ],
})
export class CharityRoleTabsComponent implements OnInit {
  currentTab: string;
  isMainPage: boolean = true;
  isSpecialPage: boolean = false;
  _imageUrl: SafeResourceUrl = 'https://ionicframework.com/docs/img/demos/thumbnail.svg';
  private readonly subscriptions = new Subscription();
  notificationCount: number = 0;
  private isNavigating: boolean = false;

  constructor(
    private navController: NavController, 
    private router: Router,
    private charityService: CharityService,
    private sanitizer: DomSanitizer,
    private notificationsService: NotificationsService,
    private tabService: TabService
  ) {
    addIcons({ searchOutline, notifications, menu });
    if(!this.currentTab) this.currentTab = this.tabService.getLastTab('charity');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.startsWith('/charity-role-tabs')) return;
        this.isMainPage = !!CHARITY_TABS.find(page => page === event.url);
        const state: StateType = this.router.getCurrentNavigation()?.extras.state || {};
        setTimeout(() =>{
          this.tabService.trackNavigation('charity', this.currentTab, event.urlAfterRedirects, state);
        }, 500);
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.charityService.charity$.subscribe(charity => {
        if(charity){
          this.getNotificationCount(charity.id);
          if(charity?.avatarUrl){
            this._imageUrl = this.sanitizer.bypassSecurityTrustUrl(charity.avatarUrl);
          }
        }
      })
    )
  }

  getNotificationCount(id) {
    this.notificationsService.loadUnreadCountByCharityId(id);
    this.subscriptions.add(
      this.notificationsService.charityNotificationCount$.subscribe(count => {
        this.notificationCount = count;
      })
    )
  }

  handleTabChange(event) {
    if (this.isNavigating) return;
    this.isNavigating = true;
    const page: string = event.tab;

    if(charityTabs.find(tab => tab === page)){
      if (page === this.currentTab) {
        this.navController.navigateRoot(`/charity-role-tabs/${page}`).finally(() => {
          this.isNavigating = false;
      });
      } else {
        this.currentTab = page;
        const lastTabPage: {url: string; state: StateType} = this.tabService.getLastTabPage('charity', this.currentTab);
        if (lastTabPage) {
          this.navController.navigateRoot(lastTabPage.url, { state: lastTabPage.state || {} }).finally(() => {
            this.isNavigating = false;
          });
        } else {
          this.isNavigating = false;
        }
      }
    } else {
      if(!this.currentTab) this.currentTab = this.tabService.getLastTab('charity');
      this.isNavigating = false;
    }
  }

  goToMenuPage() {
    this.navController.navigateForward(['/charity-role-menu']).then(r => r);
  }

  goToNotificationsPage() {
    this.navController.navigateForward(['/charity-role-tabs/charity-notifications']).then(r => r);
  }

  back() {
    if(!this.currentTab) this.currentTab = this.tabService.getLastTab('charity');
    const backTabPage: {url: string; state: StateType}  = this.tabService.navigateBack('charity', this.currentTab);
    if (backTabPage) {
      this.navController.navigateBack(backTabPage.url, { state: backTabPage.state || {} });
    } else {
      this.navController.navigateRoot(`/charity-role-tabs/${this.currentTab}`);
    }
  }
}