import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../../models/Notification';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly notificationsApiUrl = `${environment.beApiUrl}/api/v1/notifications`;
  private userNotificationCountSubject = new BehaviorSubject<number>(null);
  userNotificationCount$ = this.userNotificationCountSubject.asObservable();
  private charityNotificationCountSubject = new BehaviorSubject<number>(null);
  charityNotificationCount$ = this.charityNotificationCountSubject.asObservable();
  constructor(private httpClient: HttpClientWrapperService) {}

  getNotificationsByUserId(userId: number): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(`${this.notificationsApiUrl}?userId=${userId}`);
  }

  getNotificationsByCharityId(charityId: number): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(`${this.notificationsApiUrl}?charityId=${charityId}`);
  }

  loadUnreadCountByUserId(userId: number): void {
    this.getUnreadCountByUserId(userId).subscribe({
      next: (count) => this.userNotificationCountSubject.next(count),
      error: (err) => console.error('Failed to fetch user notification count:', err)
    })
  }

  getUnreadCountByUserId(userId: number): Observable<number> {
    return this.httpClient.get<{ count: number }>(`${this.notificationsApiUrl}/unread-count?userId=${userId}`)
    .pipe(map(response => response.count));
  }

  loadUnreadCountByCharityId(charityId: number): void {
    this.getUnreadCountByCharityId(charityId).subscribe({
      next: (count) => this.charityNotificationCountSubject.next(count),
      error: (err) => console.error('Failed to fetch charity notification count:', err)
    })
  }

  getUnreadCountByCharityId(charityId: number): Observable<number> {
    return this.httpClient.get<{ count: number }>(`${this.notificationsApiUrl}/unread-count?charityId=${charityId}`)
    .pipe(map(response => response.count));
  }

  setNotificationsToReadByUserId(userId: number): Observable<Notification[]> {
    return this.httpClient.post<Notification[], number>(`${this.notificationsApiUrl}/read-all?userId=${userId}`, userId);
  }

  setNotificationsToReadByCharityId(charityId: number): Observable<Notification[]> {
    return this.httpClient.post<Notification[], number>(`${this.notificationsApiUrl}/read-all?charityId=${charityId}`, charityId);
  }

}