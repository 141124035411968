import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { Subscription } from 'rxjs';
import {
  NavController,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonText,
  AlertController,
  IonBadge,
  IonRouterOutlet
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { User } from '../../core/models/User';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { TabService } from 'src/app/core/services/navigation/tab.service';
import { StateType } from 'src/app/core/models/StateType';

const MAIN_TABS: string[] = [
  '/user-role-tabs/home',
  '/user-role-tabs/discover',
  '/user-role-tabs/commerce',
];

const userTabs: string[] = [
  'home',
  'discover',
  'commerce',
];

@Component({
  selector: 'app-user-role-tabs',
  templateUrl: './user-role-tabs.component.html',
  styleUrls: ['./user-role-tabs.component.scss'],
  standalone: true,
  imports: [
    IonBadge,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonBadge,
    IonRouterOutlet
  ],
})
export class UserRoleTabsComponent {
  currentTab: string;
  isMainPage: boolean = true;
  loggedInUser: User;
  private readonly subscriptions = new Subscription();
  authService = inject(AuthService);
  avatarUrl: SafeResourceUrl = 'assets/ic-profile-avatar.svg';
  notificationCount: number = 0;
  private isNavigating: boolean = false;

  constructor(
    private navController: NavController,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private alertController: AlertController,
    private notificationsService: NotificationsService,
    private tabService: TabService
  ) {
    addIcons({ ...icons });
    this.getLoggedInUserInfo();
    if(!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.startsWith('/user-role-tabs')) return;
        this.isMainPage = !!MAIN_TABS.find(page => page === event.url);
        const state: StateType = this.router.getCurrentNavigation()?.extras.state || {};
        setTimeout(() =>{
          this.tabService.trackNavigation('user', this.currentTab, event.urlAfterRedirects, state);
        }, 500);
      }
    });
  }

  getLoggedInUserInfo() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getNotificationCount(user.id);
          if (user?.avatarUrl && user?.avatarUrl !== 'test') {
            this.avatarUrl = this.domSanitizer.bypassSecurityTrustUrl(user.avatarUrl);
          }
        }
      }),
    );
  }

  getNotificationCount(id) {
    this.notificationsService.loadUnreadCountByUserId(id);
    this.subscriptions.add(
      this.notificationsService.userNotificationCount$.subscribe(count => {
        this.notificationCount = count;
      }),
    );
  }

  handleTabChange(event) {
    const page: string = event.tab;
    if (this.isNavigating) return;
    this.isNavigating = true;

    if(userTabs.find(tab => tab === page)){
      if (page === this.currentTab) {
        this.navController.navigateRoot(`/user-role-tabs/${page}`).finally(() => {
          this.isNavigating = false;
      });
      } else {
        this.currentTab = page;
        const lastTabPage: {url: string; state: StateType} = this.tabService.getLastTabPage('user', this.currentTab);
        if(lastTabPage) {
          this.navController.navigateRoot(lastTabPage.url, { state: lastTabPage.state || {} }).finally(() => {
            this.isNavigating = false;
          });
        } else {
          this.isNavigating = false;
        }
      }
    } else {
      if(!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
      this.isNavigating = false;
    }
  }

  goToMenuPage() {
    this.navController.navigateForward(['/menu']).then(r => r);
  }

  goToNotificationsPage() {
    this.navController.navigateForward(['/user-role-tabs/notifications']).then(r => r);
  }

  back() {
    if(!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
    const backTabPage: {url: string; state: StateType} = this.tabService.navigateBack('user', this.currentTab);
    if (backTabPage) {
      this.navController.navigateBack(backTabPage.url, { state: backTabPage.state || {} });
    } else {
      this.navController.navigateRoot(`/user-role-tabs/${this.currentTab}`);
    }
  }

  //Todo: Refresh page data
  handleRefresh(e) {
    setTimeout(() => {
      // Any calls to load data go here
      e.target.complete();
    }, 1000);
  }

  onInvite(): void {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out the Sunny Street app!',
          text: 'I found this great app. You should check it out!',
          url: 'https://sunnystreet.app/',
        })
        .catch(error => {
          console.error('Error sharing', error);
          this.showFallbackInviteOption();
        });
    } else {
      this.showFallbackInviteOption();
    }
  }

  showFallbackInviteOption(): void {
    const shareUrl: string = 'https://sunnystreet.com/';
    navigator.clipboard.writeText(shareUrl).then(() => {
      this.alertController
        .create({
          header: 'Invitation link copied to clipboard',
          buttons: ['OK'],
          cssClass: 'alert-message',
        })
        .then((alert: HTMLIonAlertElement) => {
          alert.present();
        })
        .catch(err => {
          console.error('Failed to copy link to clipboard:', err);
          this.alertController
            .create({
              header: 'Failed to copy the link.',
              buttons: ['OK'],
              cssClass: 'alert-error',
            })
            .then((alert: HTMLIonAlertElement) => {
              alert.present();
            });
        });
    });
  }
}